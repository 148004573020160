// @import "../node_modules/bootstrap/scss/_functions";
// @import "../node_modules/bootstrap/scss/variables";
//@import "../node_modules/bootstrap/scss/bootstrap.scss";

$grid-breakpoints: (
  xs: 0,
  sm: 400px,
  md: 600px,
  lg: 800px,
  xl: 1280px
);


$theme-colors: (
    "primary": #be9d42,
    "secondary":  #be9d42,

    "warning": #be9d42,
    "danger": #be9d42,
    "info":  #be9d42,
    "success":  #be9d42,
);

@import "../node_modules/bootstrap/scss/bootstrap.scss";

* {
    font-family: 'FoubertText', 'Raleway', sans-serif;
}

.btn-secondary {
    color: white;
}

.btn {
    text-transform: uppercase
}

h2 {  
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.btn-rounded {
    border-radius: 290486px;
    padding-left: 1em;
    padding-right: 1em;
}